@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-weight: 600;
  }
}

body {
  margin: 0;
}

.container-width {
  max-width: 1167px;
  width: 100%;
}

@media (max-width: 1166px) {
  .slider-container-size-fix {
    width: calc(100vw - 1rem);
  }
}

/* .embla {
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 70%;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
} */
